import {Icon, Swipe, SwipeItem, Image, Toast} from 'vant'
export default {
  components:{
    'van-icon':Icon,
    'van-swipe':Swipe,
    'van-image':Image,
    'van-swipe-item':SwipeItem,
  },
  data () {
    return {
      apis:{
        scenic:'/seller/find',//获取景点信息
        types:'/commodity/queryCommodityTypeBySellerId',//商品类型ID
        tickets: '/commodity/queryCommodity',//商家商品列表
      },
      bizType: 3,//商家统一业务类型
      scenicKey: null,//景区ID
      scenic:{},//景区信息
      imgs:[],//景区图片列表
      colorState:false,
      videos:[],//视频列表
      types:[],//门票类型
      ticketParams:{//门票查询参数
        sellerId: null,//商家ID
        commodityTypeId:null,//分类
      },
      tickets:[],//门票列表
      playing:false,//是否正在播放音频
    }
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  mounted() {
    const me = this;
    me.scenicKey  = me.$route.params.id;
    me.loadScenic();
    me.loadTickets();
    window.addEventListener('scroll',this.scrollFunc,true)
  },
  methods: {
    scrollFunc(){
      let top = document.body.scrollTop||document.documentElement.scrollTop
      console.log(top)
      if(top>30){
        this.colorState = true
      }else{
        this.colorState = false
      }
    },
    goMap(){
      let isMap = JSON.parse(localStorage.getItem('map')) ? true : false;
      if(!isMap) return Toast('定位失败，无法导航');
      let locations = JSON.parse(localStorage.getItem('map')).position;
      let ipGo=null;
      // ipGo = `https://uri.amap.com/navigation?from=${locations.lng},${locations.lat},起点&to=${this.scenic.longitude},${this.scenic.latitude},${this.scenic.name}&callnative=1`
      ipGo = `https://www.amap.com/dir?from[name]=起点&from[lnglat]=${locations.lng},${locations.lat}&to[name]=${this.scenic.name}&to[lnglat]=${this.scenic.longitude},${this.scenic.latitude}&policy=1&type=car&callnative=1`

      // window.open(ipGo)
      this.$router.push({
        path:'/outWindow',
        query:{
          interlinkage:encodeURI(ipGo)
        }
      })
    },
    loadScenic(){//获取景点详情
      const me = this;
      me.scenic = {};
      me.imgs = [];
      me.$get({
        url:me.apis.scenic,
        params: {
          sellerId: me.scenicKey,
          token: me.$global.token
        }
      }).then(rsp => {
        me.scenic = rsp;
        if(rsp && rsp.attachments && rsp.attachments.length > 0){
          var files = rsp.attachments;
          for(var i = 0, n = files.length; i < n; i++){
            if(files[i].fileType && files[i].fileType == 1){
              me.imgs.push(files[i].url);
            }else if(files[i].fileType && files[i].fileType == 2){
              me.videos.push(files[i].url);
            }
          }
        }
        if(me.imgs.length <= 0){
          me.imgs.push(me.scenic.cover);
        }
      });
    },
    loadTickets(){//获取商家的门票列表
      const me = this;
      me.ticketParams.sellerId = me.scenicKey;
      me.$get({
        url:me.apis.tickets,
        params: me.ticketParams
      }).then(rsp => {
        me.tickets = rsp.data;
      });
    },
    praiseOrCollect(){//点赞或收藏后触发
      this.loadScenic();
    }
  }
}
